import { useEventListener } from '@vueuse/core'
import { pixels as breakpoints } from '../tailwind.config.screens'

/**
 * Composable function for managing window size reactivity.
 * Provides current window width and computed properties to determine window size category.
 */
export const useWindow = () => {
  /**
   * Ref to store the current width of the window.
   * Defaults to the smallest breakpoint or window's inner width.
   */
  const windowWidth = ref(window?.innerWidth || breakpoints.sm)

  /**
   * Sets up an event listener on window resize to update `windowWidth`.
   */
  onMounted(() => {
    useEventListener('resize', () => {
      windowWidth.value = window.innerWidth
    })
  })

  /**
   * Computed property to determine if the window is in the 'small' size category.
   */
  const isWindowSmall = computed(() => windowWidth.value < breakpoints.sm)

  /**
   * Computed property to determine if the window is in the 'medium' size category.
   */
  const isWindowMedium = computed(() => windowWidth.value < breakpoints.md)

  return {
    windowWidth,
    isWindowSmall,
    isWindowMedium
  }
}
