export const pixels = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
} as const

export const css = {
  sm: `${pixels.sm}px`,
  md: `${pixels.md}px`,
  lg: `${pixels.lg}px`,
  xl: `${pixels.xl}px`,
  '2xl': `${pixels['2xl']}px`
} as const
